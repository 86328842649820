@media(min-width:768px){
    html, body {
        margin: 0;
        height: 100%;
    }

    header{
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: 100;
        color: #fff;
        font-size: 18px;
        background: #3f4d67;
    }
    
    .mainContainer{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .mainContainer .containerLeft{
        width: 50%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center ;
        background-color: transparent;
    }

    .mainContainer .containerLeft .inputContainer{
        margin: 2%;
    }

    .mainContainer .containerLeft .buttonEnviar{
        height: 40px;
        width: 80px;
        border-radius: 10%;
        background-color: #3f4d67;
        border-color: white;
        color: white;
    }

    .mainContainer .containerLeft .buttonEnviar:hover{
        cursor: pointer;
    }

    .mainContainer .containerRight{
        width: 50%;
        text-align: center;
    }

    .iconoWhatsapp:hover{
        cursor: pointer;
    }
}


@media(max-width:768px){
    html, body {
        margin: 0;
        height: 100%;
    }

    header{
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: 100;
        color: #fff;
        font-size: 18px;
        background: #3f4d67;
    }

    .mainContainer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mainContainer .containerLeft{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items:center ;
        background-color: transparent;
    }

    .mainContainer .containerLeft .buttonEnviar{
        height: 40px;
        width: 80px;
        border-radius: 10%;
        background-color: #3f4d67;
        border-color: white;
        color: white;
    }

    .mainContainer .containerLeft .inputContainer{
        margin: 2%;
    }

    .mainContainer .containerRight{
        width: 95%;
        text-align: center;
        margin-top: 15%;
        font-size: 150%;
    }
}